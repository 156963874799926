import React from 'react';
import ContactUsForm from '../../components/contact-us/ContactUsForm';
import MarketingLayout from '../../components/layout/MarketingLayout';
import PageHeader from '../../components/layout/PageHeader';
import PageSection from '../../components/ui/PageSection';
import Spacing from '../../components/ui/Spacing';
import Text from '../../components/ui/Text';

const ContactUsPage: React.SFC = () => (
  <MarketingLayout title="Contact Us">
    <Spacing bottom={5}>
      <PageHeader
        title="Send us a message"
        description={
          <>
            Use the form below or email us at{' '}
            <Text link heavyUnderline>
              <a href="mailto:support@slab.com">support@slab.com</a>
            </Text>
          </>
        }
      />
    </Spacing>
    <PageSection narrow>
      <Spacing bottom={5}>
        <ContactUsForm />
      </Spacing>
    </PageSection>
  </MarketingLayout>
);

export default ContactUsPage;
