import { navigate } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import submitForm from '../../utils/submitForm';
import Form, { Field, Submit } from '../ui/Form';
import Spacing from '../ui/Spacing';

const MAX_ATTACHMENTS_SIZE_IN_MB = 25;

type ContactUsFormValues = {
  email: string;
  subject: string;
  details: string;
  attachments: File[];
};

const handleSubmit = (values: ContactUsFormValues) =>
  submitForm<ContactUsFormValues>(process.env.FORM_ID_CONTACT_US, values).then(
    () =>
      navigate(`${window.location.pathname.replace(/\/+$/, '')}/confirmation`),
  );

const validateAttachments = (files: File[]) => {
  let errorMessage;
  const totalSizeInBytes = _.sumBy(files, 'size');
  if (totalSizeInBytes > MAX_ATTACHMENTS_SIZE_IN_MB * 1000 * 1000) {
    errorMessage = `The total size of all attached files cannot exceed ${MAX_ATTACHMENTS_SIZE_IN_MB}MB.`;
  }

  return errorMessage;
};

const ContactUsForm: React.SFC = () => (
  <Form
    initialValues={{
      email: '',
      subject: '',
      details: '',
      attachments: [],
    }}
    onSubmit={handleSubmit}
    validateOnBlur
    validateOnChange
  >
    <Field
      name="email"
      label="Email Address"
      type="email"
      required
      autoComplete="email"
      placeholder="gavin@hooli.com"
    />
    <Field
      name="subject"
      label="Subject"
      type="text"
      required
      placeholder="What's this about?"
    />
    <Field
      name="details"
      label="Details"
      type="textarea"
      required
      placeholder="How can we help?"
    />
    <Field
      validate={validateAttachments}
      name="attachments"
      label="Attach Files (optional)"
      type="file"
    />
    <Spacing top={2}>
      <Submit block disableUntilValid color="red">
        Send
      </Submit>
    </Spacing>
  </Form>
);

export default ContactUsForm;
